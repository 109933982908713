@mixin flx-elevation($z-value, $color: $mdc-elevation-baseline-color, $opacity-boost: 0) {
  @if type-of($z-value) != number or not unitless($z-value) {
    @error "$z-value must be a unitless number, but received '#{$z-value}'";
  }

  @if $z-value < 0 or $z-value > 24 {
    @error "$z-value must be between 0 and 24, but received '#{$z-value}'";
  }

  @if map-has-key($mdc-theme-property-values, $color) {
    $color: map-get($mdc-theme-property-values, $color);
  }

  @if type-of($color) != color {
    @error "$color must be a valid color, but '#{$color}' is of type #{type-of($color)}";
  }

  $umbra-z-value: map-get($mdc-elevation-umbra-map, $z-value);
  $penumbra-z-value: map-get($mdc-elevation-penumbra-map, $z-value);
  $ambient-z-value: map-get($mdc-elevation-ambient-map, $z-value);

  $umbra-color: rgba($color, $mdc-elevation-umbra-opacity + $opacity-boost);
  $penumbra-color: rgba($color, $mdc-elevation-penumbra-opacity + $opacity-boost);
  $ambient-color: rgba($color, $mdc-elevation-ambient-opacity + $opacity-boost);

  box-shadow:
          0 0 0 2px $mdc-theme-primary,
          #{"#{$penumbra-z-value} #{$penumbra-color}"},
          #{$ambient-z-value} $ambient-color;
}


@function flx-elevation-transition-rule(
  $duration: $mdc-elevation-transition-duration,
  $easing: $mdc-elevation-transition-timing-function) {
  @return #{$mdc-elevation-property} #{$duration} #{$easing};
}

@mixin flx-elevation-transition(
  $duration: $mdc-elevation-transition-duration,
  $easing: $mdc-elevation-transition-timing-function) {
  transition: flx-elevation-transition-rule($duration, $easing);
  will-change: $mdc-elevation-property;
}

@for $z-value from 0 through 24 {
  .flx-elevation--z#{$z-value} {
    @include flx-elevation($z-value);
  }
}

.flx-elevation-transition {
  @include flx-elevation-transition;
}

.flx-elevation {
  @include flx-elevation(2);
  @include flx-elevation-transition;

  &:hover,
  &:focus {
    @include flx-elevation(4);
  }

  &:active {
    @include flx-elevation(8);
  }
}