// "variables"; this file is auto injected by webpack based on environment
@import '../../../../node_modules/material-components-web/material-components-web.scss';
@import 'flx/hacks';
@import 'flx/border';
@import 'flx/tooltip';
@import 'flx/elevation';
@import 'flx/esignature';
@import 'flx/custom_icons';
@import 'flx/margin_padding';
@import 'flx/testcenter';
@import 'flx/services';
@import 'old_design_adaptations';
@import 'mixins/breakpoints';

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
input,
select,
textarea {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  background-color: $mdc-theme-background !important;
}

input,
select,
textarea {
  color: $flx-theme-text;
}

body a {
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

/*
 * Overrides to Material Theme's Components
 */

/* Important to make custom font weight */
.mdc-button {
  font-weight: 600;
  border-radius: 4px;
  &--flat {
    background-color: $mdc-theme-background !important;
  }
}

.mdc-button--primary.mdc-button--raised,
.mdc-fab--primary {
  color: #ffffff; // important to overcome Material's HSL issue
  &:not([disabled]) {
    box-shadow: 0 0 8px 0 rgba($mdc-theme-primary, 0.12),
      0 4px 8px 0 rgba($mdc-theme-primary, 0.24);
    @include mdc-elevation-transition;
  }
  &:hover,
  &:focus {
    box-shadow: 0px 5px 5px -3px rgba($mdc-theme-primary, 0.2),
      0px 8px 10px 1px rgba($mdc-theme-primary, 0.14),
      0px 3px 14px 2px rgba($mdc-theme-primary, 0.12);
  }
  &:active {
    background-color: darken($mdc-theme-primary, 5%);
    box-shadow: 0px 7px 8px -4px rgba($mdc-theme-primary, 0.2),
      0px 12px 17px 2px rgba($mdc-theme-primary, 0.14),
      0px 5px 22px 4px rgba($mdc-theme-primary, 0.12);
  }
}

.mdc-card {
  background: #ffffff;
  border-radius: $flx-border-radius;
  overflow: visible;

  &.flx-border--primary {
    @include mdc-theme-prop(border-color, $mdc-theme-primary);
  }
  &.flx-border--plain {
    @include mdc-theme-prop(border-color, $flx-border-plain);
  }
  &.flx-elevation--resting {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    &__border--primary {
      box-shadow: 0 0 0 2px $mdc-theme-primary, 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }
  &.flx-elevation--raised {
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    &__border--primary {
      box-shadow: 0 0 0 2px $mdc-theme-primary, 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    }
  }
  &__title {
    &--secondary {
      font-size: 16px;
      margin-left: 13px;
    }
  }
}

.mdc-tab {
  font-weight: normal;
  color: rgba($flx-theme-text, 0.6);
  width: auto;
  &--active {
    color: $mdc-theme-primary;
    font-weight: 500;
  }
  &:hover {
    color: $flx-theme-text;
  }
}

.mdc-list-item {
  white-space: inherit;
  overflow: visible;
}

.mdc-textfield {
  &--textarea {
    display: block;

    textarea.mdc-textfield__input {
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      padding: 16px;
      resize: none;
      width: 100%;
    }

    &:not(.mdc-textfield--upgraded) {
      &:not(.mdc-textfield--multiline) {
        textarea.mdc-textfield__input {
          border: 1px solid rgba(0, 0, 0, 0.06);
        }
      }
    }
  }
}

.mdc-snackbar {
  z-index: 9999;
  opacity: 0;
  transition: all 0.25s 0ms;
}

.mdc-snackbar__action-button {
  font-weight: 700;
}

.mdc-snackbar--active {
  opacity: 1;
}

.material-icons {
  color: $flx-theme-text;
  &--small {
    font-size: 10px !important;
  }
  &--color {
    &-danger {
      color: $flx-theme-danger;
    }
    &-success {
      color: $flx-theme-success;
    }
    &-warning {
      color: $flx-theme-warning;
    }
  }
}

/*
 * Custom classes and components
 */

.flx-button--icon {
  margin: auto 8px;
  cursor: pointer;
  outline: none;
  user-select: none;
  &-primary {
    color: $mdc-theme-primary !important;
    &::before,
    &::after {
      background-color: rgba($mdc-theme-primary, 0.2);
    }
  }
  &-success {
    color: $flx-theme-success !important;
    &::before,
    &::after {
      background-color: rgba($flx-theme-success, 0.2);
    }
  }
  &-danger {
    &:hover {
      color: $flx-theme-danger !important;
    }
    &::before,
    &::after {
      background-color: rgba($flx-theme-danger, 0.2);
    }
  }
}

.flx-theme--text {
  color: $flx-theme-text;
  &-light {
    color: rgba($flx-theme-text, 0.6);
  }
  &-danger {
    color: $flx-theme-danger !important;
  }
}

.flx-theme--link {
  text-decoration: none;
  color: $mdc-theme-primary;
  /*This meant to be removed (&:hover) as soon as marketplace.css is not needed anymore*/
  &:hover {
    color: $mdc-theme-primary;
  }
  &-light {
    color: $mdc-theme-primary-light;
  }
  &-dark {
    color: $mdc-theme-primary-dark;
  }
}

.flx-button--icon {
  margin: auto 8px;
  cursor: pointer;
  outline: none;
  user-select: none;
  &-primary {
    color: $mdc-theme-primary;
    &::before,
    &::after {
      background-color: rgba($mdc-theme-primary, 0.2);
    }
  }
  &-success {
    color: $flx-theme-success !important;
    &::before,
    &::after {
      background-color: rgba($flx-theme-success, 0.2);
    }
  }
  &-danger {
    &:hover {
      color: $flx-theme-danger !important;
    }
    &::before,
    &::after {
      background-color: rgba($flx-theme-danger, 0.2);
    }
  }
}

/* Shake Animation */
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
