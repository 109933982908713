.testcenter-layout {
  .flx-menu-header {
    min-height: 56px;
  }

  #test-center {
    // material web components hacks
    // 0.25.x -> 0.40.x
    .mdc-button {
      align-items: center;
      display: inline-flex;
      justify-content: center;
    }

    .mdc-switch {
      outline: none;
    }
  }

  .flx-menu-header .flx-app__logo {
    padding: 8px 0;

    a {
      display: inline-block;
      height: 40px;

      img {
        margin-top: 0;
        vertical-align: middle;
      }
    }

    .flx-app__logo-separator {
      border-left: 1px solid lighten($flx-theme-text, 55%);
      display: inline-block;
      height: 25px;
      margin-left: 1ex;
      padding-left: 1ex;
      width: 1px;
      vertical-align: middle;
    }
  }

  .flx-menu-header .flx-top-icon-menu {
    grid-column-end: span 2;
  }

  /* Phone */
  @media (min-width: map-get($mdc-layout-grid-breakpoints, 'phone')) and (max-width: map-get($mdc-layout-grid-breakpoints, 'tablet') - 1px) {
    .flx-menu-header .flx-app__logo {
      .flx-app__logo-separator,
      .flx-app__logo-addition {
        display: none;
      }
    }
  }
}
