@mixin unsupported-browser-warning {
    body.browser-unsupported {
        .browser-warning {
            display: block;
        }

        .mdc-layout-grid__inner {
            // prevent the top bar from overlapping the banner
            margin: 0;
        }
    }

    .browser-warning {
        display: none; // hide the warning by default, so it doesn't "flash" on supported devices until the browser is detected

        background: #CC7D35;
        text-align: center;

        .page-container {
            position: fixed;
            z-index: 500;

            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            overflow-x: auto;

            background: white;

            font-size: 14px;

            .image-row {
                text-align: left;
            }

            .browser-check-logo {
                margin-top: 28px;
                margin-left: 14px;
            }

            .browser-check-content {
                margin: 0 auto;
            }

            .browser-check-content-container {
                width: 100%;
            }

            .browser-check-content-container .desktop {
                max-width: 375px;
                margin-top: 40px;
            }

            .heading h2 {
                font-size: 24px;
                color: #001946;
            }

            .message p {
                font-size: 16px;
                color: #4A4A4A;
                margin-top: 4px;
                margin-bottom: 14px;
            }

            .heading h3 {
                font-size: 14px;
                font-weight: 600;
                color: #4A4A4A;
                margin-top: 2px;
                margin-bottom: 0;
            }

            .download-link {
                font-size: 12px;
                color: #001946;
                text-decoration: underline;
                margin-bottom: 20px;
                letter-spacing: 0;
            }

            .browser-check-content .wrapper {
                height: 50px;
                position: relative;
            }
        }

        .warning-container {
            display: block;
            padding: 5px 16px 7px 16px;

            .warning-icon, .warning-text {
                color: white;
            }

            .warning-icon {
                font-size: 16px;
                margin-right: 7px;
                padding-bottom: 2px;
            }

            .warning-text {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
            }
        }

        @media screen and (max-width: 768px) {
            background: #CC8E35;

            .warning-text {
                display: block;
            }
        }
    }
}