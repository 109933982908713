@import "/var/www/maklerportal/project/maklerportal/web/src/theme/_variables.scss";@import "/var/www/maklerportal/project/maklerportal/web/src/dashboard/scss/dashboard.scss";
/* Base theme with components */
@import 'theme';
@import 'flx/autocomplete';
@import 'common/web/src/partials/unsupported_browser_warning';
@import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';

/*
 * FIXME: Remove, once we can get rid of bootstrap
 *
 * Since bootstrap is changing the base font size by setting it in html
 * we need to overwrite it to make our styles work, because they are
 * based on a base font size of 16px, not 10px as in bootstrap
 */
html {
  font-size: 16px !important;
}

.mdc-button {
  text-transform: initial;
}

/* Menu Component */
.mdc-simple-menu {
  border-radius: $flx-border-radius;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);

  & .mdc-list {
    padding: 0;
  }

  & .mdc-list-item {
    color: $flx-theme-text;

    &:hover {
      background-color: #f5f5f5;
    }

    &:active,
    &::before,
    &:focus {
      background-color: #efefef;
    }

    & a {
      text-decoration: none;
      color: $flx-theme-text;
    }

    &.mdc-list-item--disabled {
      opacity: 0.6;
    }
  }

  & .mdc-list-divider {
    border-color: #eeeeee;
  }
}

/* List component */
.mdc-list-group {
  &.flx-list-group {
    padding: 0;

    .flx-meeting {
      display: flex;
      max-height: 200px;
      transition: max-height 0.2s;
      cursor: pointer;

      &.mdc-list-item {
        padding: 13px 16px;
        height: auto;
      }

      &.collapsed {
        overflow: hidden;
        max-height: 0;
        padding: 0;
      }

      .flx-button--icon {
        cursor: pointer;
        outline: none;
        color: $flx-theme-text;
        text-decoration-line: none;

        :hover {
          color: $flx-theme-text;
          text-decoration-line: none;
        }
      }

      .flx-meeting--icon-waiting-confirmation {
        font-size: 13px;
        background-color: $flx-theme-warning;
        border-radius: 50%;
        color: white;
        padding: 2px;
        margin-left: 0;
      }

      .flx-meeting-details {
        margin-right: 5px;

        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        color: #3a9efb;
      }
    }

    .mdc-list-item__end-detail {
      padding-left: 8px;
      white-space: nowrap;
      box-sizing: unset;
    }

    hr:last-child {
      width: 100%;
      margin-left: 0;
    }

    .flx-no-upcoming-meetings {
      padding: 40px 0 20px 0;
      text-align: center;

      .mdc-button {
        margin: 0 auto;
      }
    }
  }

  &__header {
    font-weight: 600;
    padding: 0 16px;
  }

  &__subheader {
    font-weight: 600;
    padding: 0 16px;
  }

  & .mdc-list-item {
    cursor: default;
    padding: 0 16px;

    &:hover {
      background-color: #f5f5f5;
    }

    &::before {
      display: none;
    }

    &__start-detail {
      align-items: center;
      background: #dee5e7;
      color: $flx-theme-text;
      display: inline-flex;
      justify-content: center;
      min-width: 40px;
    }

    &__end-detail {
      color: inherit;
      text-decoration: none;
      width: auto;
      text-align: right;
      min-width: 40px;

      & .mdc-list-item {
        height: 48px;
      }
    }

    &:hover {
      & .show-hover {
        display: inline-block;
      }
    }

    & .show-hover {
      display: none;
    }
  }

  & .mdc-list-divider--inset {
    margin-left: 72px;
    width: calc(100% - 72px);
  }

  & .flx-context-menu-button {
    cursor: pointer;
  }

  & .flx-context-menu {
    & .mdc-list-item {
      cursor: pointer;
    }
  }

  & .mdc-list-item__text {
    overflow: hidden;
    flex: 1;

    .flx-meeting--headline {
      color: $flx-theme-text;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      .flx-meeting--client-fullname {
        width: auto;
        max-width: calc(100% - 40px);
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
      }
    }
  }
}

.flx-upcoming-meetings {
  & .mdc-list:last-child {
    & .mdc-list-divider:last-child {
      display: none;
    }
  }
}

/* Dashboard Home Styles */

/*
 * Overrides bootstrap behaviour, avoiding a scrollbar on dropdown menus that overflow the container.
 */
.grid-view.table-responsive {
  overflow-x: visible;
}

/*
 * Override default direction of dropup to point downwards instead of upwards.
 */
.force-down-arrow.dropup .caret {
  border-top: 4px dashed !important;
  border-top: 4px solid \9 !important;
  border-bottom: none !important;
}

.flx-app {
  &__p0 {
    padding: 0 !important;
  }

  &__m0 {
    margin: 0 !important;
  }

  &__logo {
    display: flex;
    align-items: center;
    padding: 12px 0;

    img {
      width: 100%;
      height: auto;
      max-height: 40px;
    }
  }

  &--background--image--avatar {
    background-size: cover !important;
    background-position: 50% !important;
  }

  &__help-icon {
    margin: 0;
    height: 24px;
    color: #303b44;
  }

  &__user-menu {
    height: 32px;

    &__profile-item {
      height: 80px;

      &__link {
        overflow: hidden;

        & div {
          margin-bottom: -7px;
        }
      }

      &__icon {
        margin-left: 16px;
      }
    }

    &__online-status {
      position: absolute;
      right: -2px;
      bottom: 0;
      border: 2px solid #fff;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      content: ' ';
      background-color: #999;
      box-sizing: content-box;
    }

    &__online-status-online {
      background-color: $flx-theme-success;
    }

    &__online-status-offline {
      background-color: $flx-theme-danger;
    }

    &__online-status-connecting {
      background-color: grey;
    }

    &__online-status-disconnected {
      background-color: $flx-theme-warning;
    }
  }

  /* Session Rejoin and Reminder Notification Styles */
  &__session-rejoin,
  &__session-reminder {
    padding: 16px 24px;
    min-height: 56px;
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: flex-start;

    &__avatar {
      margin-left: 0;
      margin-right: 16px;
    }

    &__text {
      flex: 1;
      flex-direction: column;
    }

    &__actions {
      display: flex;
      margin-left: auto;
      margin-right: 0;

      .flx-button--icon {
        margin: 0 16px;
        line-height: 38px;
      }

      a {
        white-space: nowrap;
      }
    }
  }

  &__session-reminder {
    margin-bottom: 8px;
  }

  /* Hero Card Styles */
  &__hero-card {
    &--background {
      background: $mdc-theme-primary;
      height: 120px;
      width: 100%;
      left: 0;
      position: absolute;
      z-index: -1;
    }

    &-background {
      &--rejoinable-session {
        height: 216px;
      }
    }

    .mdc-card__primary {
      padding: 24px;

      .mdc-card__title--large {
        margin: 0;
        padding: 0;
        font-size: 2rem;
        font-weight: 400;
        color: $flx-theme-text;
        line-height: 48px;
      }

      .mdc-card__subtitle {
        font-size: 1.5rem;
        font-weight: 400;
        margin: 0;
        line-height: 32px;
        letter-spacing: normal;
      }

      .mdc-card__actions {
        padding: 0;
        margin-top: 1rem;
        align-items: center;
        display: inline-flex;
        width: 100%;
      }

      .flx-field {
        overflow: hidden;
        position: relative;
        padding: 0 8px;
        height: 50px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;

        &__label {
          margin-left: 8px;
          margin-right: 8px;
        }

        input {
          box-shadow: none;
          width: 100%;
          flex: 1 1;
          font-size: 1rem;
          margin-left: 8px;
          border: none;
          outline: none;

          &::placeholder {
            color: rgba($flx-theme-text, 0.3);
          }

          &:-ms-input-placeholder {
            color: rgba($flx-theme-text, 0.3);
          }

          &::-ms-clear {
            display: none;
          }
        }

        button {
          margin-left: 8px;
        }

        & .mdc-linear-progress {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 2px;
        }
      }
    }
  }

  &__auto-complete {
    .mdc-list-item {
      height: 60px;
    }
  }
}

.flx-profile-card {
  position: relative;

  .flx-profile-card__link {
    position: absolute;
    top: 1rem;
    right: 0;
  }

  .flx-profile-card__context-menu {
    position: absolute;
    // `important` required because the mdc item this is targetting sets inline styles.
    top: 1rem !important;
    right: 1rem !important;
  }
}

.flx-app__e-signature,
.flx-profile-card {
  height: 100%;

  .mdc-card {
    height: 100%;
  }

  .mdc-card__actions {
    display: inline;
    font-size: 14px;
  }
}

.flx-menu-subscription-notifications {
  color: $flx-theme-text;
  text-align: center;
  font-size: 16px;
  background-color: $flx-theme-background;
  box-shadow: inset 0 -1px 0 0 #eeeeee, inset 0 -1px 0 0 #ced7df;
  height: 48px;

  & .subscription-modal-body p {
    margin-right: auto;
    margin-left: auto;
  }

  & a,
  & a:hover {
    font-size: 14px;
    text-decoration: none;
    line-height: 35px;
  }

  & .dashboard-subscription-reminder {
    font-size: 14px;
    text-decoration: none;
    line-height: 48px;
  }
}

@include unsupported-browser-warning;

.flx-menu-header {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  position: relative;
  z-index: 301; //overading z-index of Iris NawDrawer

  & .mdc-layout-grid__cell {
    margin-bottom: 0;
  }

  & .mdc-list-item {
    width: 218px;
    align-items: center;

    &::before {
      display: none;
    }

    & a {
      width: 100%;
    }
  }

  .copy-profile-link-btn {
    display: flex;
    align-items: center;
  }

  .expert-url-displayed {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.flx-header-grid {
  max-width: 1440px;
  padding: 0 16px;
}

.flx-content-grid {
  margin: 0 auto;
  padding-top: 0;
  max-width: 1024px;
  width: 100%;
}

.mdc-simple-menu {
  right: 0 !important;
  left: auto !important;
  overflow: visible;
  max-height: none;
}

.mdc-tab-bar {
  margin: inherit;
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 24px;
}

.mdc-tab-bar-scroller {
  overflow: hidden;
  position: relative;
}

.mdc-tab-bar-scroller__indicator {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 42px;
}

//services flags specific style
.mdc-tab-bar-scroller__indicator--flag {
  cursor: pointer;
  background-color: transparent;
  z-index: 1;
  width: 24px;
}

.mdc-tab.mdc-tab--flag {
  text-transform: none;
}

#scrollable-tab-bar-scroller section.toolbar-flag {
  padding: 0 0 4px 0;
}

.basic-demo-toolbar.show-scroll-menu {
  overflow-x: auto;
  align-self: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mdc-tab {
  min-height: initial;
  height: initial;
  min-width: auto;
  padding: 0;
  font-size: 0.875rem;
  letter-spacing: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #838e9d;
  text-decoration: none !important; // remove it when bootstrap dependency is completely migrated to new material design

  &::after {
    width: 0 !important;
  }

  & a:hover {
    text-decoration: none !important; // remove it when bootstrap dependency is completely migrated to new material design
  }

  .unread-messages {
    background-color: #3a9efb;
    color: #ffffff;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    border-radius: 20px;
    display: none;
    height: 20px;
    width: 20px;
    padding: 1px;

    &.active {
      display: inline-block;
    }
  }
}

.mdc-tab.mdc-tab--active {
  font-weight: 500;
  opacity: 1 !important;
  color: #071c3b; //dark-80
}

.flx-avatar {
  align-items: center;
  background: #dee5e7;
  color: $flx-theme-text;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  overflow: visible;
  position: relative;

  &--r32 {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 12px;

    & img {
      width: 32px;
      height: 32px;
    }
  }

  &--r40 {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 16px;
    cursor: default !important;

    & img {
      width: 40px;
      height: 40px;
    }
  }

  &--r96 {
    height: 96px;
    width: 96px;
    border-radius: 50%;
    font-size: 32px;

    & img {
      border-radius: 50%;
      width: 96px;
      height: 96px;
    }
  }
}

.flx-avatar-topnav img {
  border-radius: 50%;
}

.hidden {
  display: none;
}

.flx-section-content {
  & .dz-success-mark i.material-icons {
    color: $flx-theme-success;
  }

  .mdc-card {
    & section {
      padding-bottom: 16px;
      padding-top: 0;
      font-size: 16px;

      & a:hover {
        text-decoration: none;
      }

      & h1 {
        color: $flx-theme-text;
        font-size: 24px;
      }

      &.card-logo {
        padding-top: 39px;
      }

      &.mdc-card__supporting-text {
        color: $flx-theme-text;
      }

      &.mdc-card__actions {
        font-size: 14px;
      }
    }
  }

  & .left-side {
    & section {
      text-align: center;
    }
  }
}

/* Custom Styles/Overrides */
.zEWidget-launcher {
  visibility: hidden;
}

.flx-call {
  &-blur {
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    filter: blur(25px);
  }

  &-overlay {
    z-index: 2;
    position: fixed;
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
    background-color: rgba(89, 98, 106, 0.7);
    text-align: center;

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 300px;

      & p {
        color: white;
        font-size: 24px;
        line-height: 1.5em;
      }
    }

    &__avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      font-size: 64px;
      cursor: auto;
    }

    &__action {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      & .material-icons {
        cursor: pointer;
        background-color: $flx-theme-phone-message;
        width: 64px;
        height: 64px;
        font-size: 32px;
        line-height: 64px;
        color: white;
        border-radius: 50%;
        margin: 0 16px;

        &:hover {
          background-color: lighten($flx-theme-phone-message, 10%);
        }

        &.flx-call-icon--accept {
          background-color: $flx-theme-phone-accept;

          &:hover {
            background-color: lighten($flx-theme-phone-accept, 10%);
          }
        }

        &.flx-call-icon--decline {
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);

          background-color: $flx-theme-phone-decline;

          &:hover {
            background-color: lighten($flx-theme-phone-decline, 10%);
          }
        }
      }
    }
  }
}

_:-ms-fullscreen {
  min-height: 54px;
}

//IE11 style
_:-ms-fullscreen {
  height: 100vh;
}

//IE11 style

/* Styling AutoComplete for Hero Card */
.autocomplete-suggestions {
  padding: auto 16px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 10px 10px 0 rgba(0, 0, 0, 0.06);
  max-height: 240px;

  .mdc-list-item {
    color: $flx-theme-text;
    height: 60px;
    padding: 0 16px;

    &__start-detail {
      margin-right: 16px;
      min-width: 40px;
    }
  }
}

.rct_panel-content {
  .rct_panel-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    .rct_panel-form {
      padding: 20px 30px 5px 30px;
    }

    .rct_panel-label {
      font-size: 12px;
      color: #747c88;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      width: 100%;
    }

    .rct_panel-label_large {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #01091b;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }

    .rct_panel-input {
      color: #15161a;
      font-size: 14px;
      border: 1px solid #d1d5dd;
      box-sizing: border-box;
      border-radius: 6px;
      height: 40px;
      margin-top: 5px;
      padding-left: 10px;
    }

    .rct_panel-input--danger {
      margin: 0;
      color: $flx-theme-danger;
      font-size: 14px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari */
      font-weight: normal;
      font-size: 14px;
      color: #acb0b8;
      opacity: 1;
      /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      font-weight: normal;
      font-size: 14px;
      color: #acb0b8;
    }

    .rct_panel-double-input_container {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .rct_panel-relative_container {
      position: relative;
    }

    .rtc_panel-select {
      appearance: none;
      background: transparent;
      width: 100%;
    }

    .rct_panel-select_icon {
      position: absolute;
      right: 15px;
      top: 45px;
      z-index: -1;
    }

    .rct_panel-textarea {
      height: 110px;
      padding-top: 10px;
    }
  }

  .rct_panel-button {
    min-height: 40px;
    width: auto;
    border-radius: 6px;
    border: none;
    word-break: break-word;

    //text
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px 16px;
    white-space: nowrap;
    line-height: 1.4;
  }

  .rct_panel-button_left {
    background: #f1f2f4;
    color: #15161a;
  }

  .rct_panel-button_middle {
    color: #3a9efb;
    background: #ffffff;
    border: #3a9efb solid 1px;
  }

  .rct_panel-button_right {
    background: $mdc-theme-primary;
    color: #ffffff;
  }

  .rct_panel-button_loading {
    opacity: 0.5;
  }

  .rct_panel-button_loader {
    margin-right: 8px;
  }
}

/**
  DEFAULT REACT NOTIFICATION
 */

.rct_notification-container {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  line-height: 19px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: auto;
  width: 400px;
  z-index: 302; //the dashboard navigation has 301

  .rct_notification {
    position: fixed;
    bottom: 0;
    right: 0;
    height: auto;
    margin: 35px;
    display: flex;
    padding: 16px;
    width: 300px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .rct_notification-icon {
      margin-right: 15px;
    }
  }
}

/**
  CALENDAR MEETING MODAL
 */
.rct_panel-content {
  .rct_panel-label {
    textarea {
      resize: none;
    }
  }

  .date-input_container {
    width: 50%;
  }

  .date-input {
    width: 90%;
    margin-right: 10px;
    padding-left: 30px !important;
  }

  .date-input_icon {
    position: absolute;
    left: 10px;
    top: 41px;
    z-index: 1;
  }

  // REACT DATE PICKER
  .react-datepicker__header {
    background-color: white;
    border-bottom: 0;
  }

  .react-datepicker__triangle {
    border-bottom-color: white;
  }

  .react-datepicker__time-container {
    .react-datepicker__time {
      .react-datepicker__time-box {
        ul.react-datepicker__time-list {
          li.react-datepicker__time-list-item--selected {
            background-color: $mdc-theme-primary;

            :hover {
              background-color: $mdc-theme-primary;
            }
          }
        }
      }
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $mdc-theme-primary;
  }

  .mdc-linear-progress {
    height: 2px;
  }

  .rct_panel-time_container {
    display: flex;
    flex-direction: row;
    width: 60%;
  }

  .start-time_container {
    position: relative;
    width: 100%;
  }

  .start-time {
    border-radius: 6px 0 0 6px !important;
    border-right: 0 !important;
    padding-left: 35px !important;
    width: 100%;
  }

  .start-time_icon {
    position: absolute;
    z-index: 1;
    top: 17px;
    left: 5px;
    margin-left: 5px;
  }

  .time-select {
    position: absolute;
    top: 73px;
    width: 100%;
    padding: 8px 0;
    box-sizing: border-box;
    z-index: 303;

    background-color: white;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: #3c4858;
  }

  .react-timeslot {
    cursor: pointer;

    display: flex;
    align-items: center;
    padding-left: 16px;
    box-sizing: border-box;

    &:hover {
      background-color: #f3f5f9;
    }
  }

  .middle-dash {
    border-radius: 0;
    border-top: 1px solid #d1d5dd;
    border-bottom: 1px solid #d1d5dd;
    width: 20px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f5f9;
    flex-shrink: 0;
  }

  .end-time {
    border-radius: 0 6px 6px 0 !important;
    border-left: 0 !important;
    width: 100%;
  }

  .start-timepicker,
  .end-timepicker {
    table {
      td {
        border-top: none;
        color: #15161a;
      }
    }
  }

  .start-timepicker {
    width: 55%;
  }

  .end-timepicker {
    width: 45%;
  }

  .timepicker-icon {
    font-size: 10px;
    color: #747c88;
  }

  #add_participants_container {
    display: flex;
    flex-direction: column;
  }

  .address-rct_panel-label {
    margin-bottom: 10px !important;
  }

  .address-container {
    margin-bottom: 15px;
  }

  .double-input_child {
    width: 50%;
    flex-grow: 0;
    margin-right: 5px;
  }
}

/* CALENDAR MEETING DETAILS PANEL */
.rct_panel-content {
  .rct_panel-body {
    .rct_panel-meeting_details {
      padding: 20px 25px 10px 25px;

      .rct_panel-meeting_detail-banner_text {
        color: #3a9efb;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 10px;
      }

      .rct_panel-double_container {
        display: flex;
        flex-direction: column;

        .rct_panel-detail_sublabel {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.4px;
          font-weight: 400;
          margin-bottom: 16px;
        }
      }

      .rct_panel-meeting_detail-goto_button--disabled {
        opacity: 0.5;
        user-select: none;
      }

      .rct_panel-meeting_detail-goto_button {
        background: $mdc-theme-primary;
        border: 1px solid $mdc-theme-primary;
        box-sizing: border-box;
        border-radius: 6px;
        min-height: 40px;
        min-width: 170px;
        color: white;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
      }

      .rct_panel-meeting_detail-goto_icon {
        margin-left: 10px;
      }
    }

    .rct_panel-button_cancel {
      background-color: #0b2a58;
      color: white;
    }

    .locked_input-status_reminder {
      color: #3a9efb;
      cursor: pointer;
    }

    .locked_input-status_notification {
      position: absolute;
      right: 15px;
      bottom: 9px;
    }

    .locked_input-status_notification-error {
      bottom: 13px;

      path {
        fill: #c0392b;
      }
    }
  }
}

.cancel_meeting_note {
  width: 100%;
  margin-top: 12px;
  height: 124px;
  background: #f3f5f9;
  border-radius: 6px;
  padding: 12px;
  border: none;
  resize: none;
}

.decline_meeting_note {
  width: 100%;
  margin-top: 12px;
  height: 124px;
  background: #f3f5f9;
  border-radius: 6px;
  padding: 12px;
  border: none;
  resize: none;
}

// We want the panel above the app to have the possibility to have the same height as flx-sections
.flx-sections {
  position: relative;
}

/*
 * IMPORTANT: For Responsiveness
 *
 * please keep this in the end to avoid
 * potential use !important to override properties
 *
 * Override/Declare Responsive only properties
 */
@import 'flx/responsive';
