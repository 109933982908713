$primary-color: #b11a3b;
$secondary-color: #f5f6f8;
$text-color: #333333;

$top-bar-background-color: $primary-color;
$logo-background-color: $primary-color;
$body-background-color: $secondary-color;

body.skin-blue{

  header.main-header .navbar{
    background-color: $top-bar-background-color;

    .sidebar-toggle:hover {
      background-color: darken($primary-color,5%);
    }
  }
  .main-header .logo {
    background-color: #fff;
    height: 52px;

    &:hover {
      background: #fff;
      opacity: 0.8;
    }

    img {
      margin-top: 5px;
      margin-left: 10px;
    }
  }
  .wrapper,
  .main-sidebar,
  .left-side{
    background-color: $body-background-color;
  }
  .content-wrapper{
    background-color: $body-background-color;
  }
  .main-sidebar,
  .left-side{
    a{
      color: #666;
    }
    li {
      a:hover {
        background-color: #BD425D;
      };
      a:active,
      a:focus,
      &.active a{
        color: #333;
        background-color: darken($body-background-color,5%);
        border-left-color: $logo-background-color;
      }

      &.dropdown a, &.dropdown.open a{
        background-color: $body-background-color;
        color: #666;
        &:hover,
        &:active,
        &:focus{
          color: #333;
          background-color: darken($body-background-color,5%);
          border-left-color: $logo-background-color;
        }
      }
      .dropdown-menu{
        background-color: $body-background-color;
        margin-left: 10px;
        border-radius: 0;
        border-width: 0;
        box-shadow: 0px 1px 2px darken($body-background-color, 90%);
      }
    }
  }

  .main-sidebar {
    li {
      a:hover {
        border-left-color: $primary-color;
      }
    }
  }


  .user-panel>.info,
  .user-panel>.info>a {
    color: #333;
  }
}

a {
  color: $top-bar-background-color;
  &:hover {
    color: darken($top-bar-background-color, 10%);
  }
}

.avatar{
  background-color: white;
}

.btn {
  &.survey-button {
    &.yes, &.no {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
      color: white !important;
      &:hover,
      &:active,
      &:focus {
        border-color: darken($primary-color, 5%);
      }
    }
  }
}