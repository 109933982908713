/*
 * Application's custom CSS Classes for responsiveness
 */

/* Phone */
@media (min-width: map-get($mdc-layout-grid-breakpoints, 'phone')) and (max-width: map-get($mdc-layout-grid-breakpoints, 'tablet') - 1px) {
  .hidden-phone {
    display: none !important;
  }
  .visible-phone {
    display: block !important;
  }
  .mdc-dialog__surface {
    margin: 0 24px;
  }
  .flx-app__hero-card {
    button,
    a {
      flex-basis: 100%;
      margin-top: 16px;
    }
  }
  _:-ms-fullscreen {
    min-height: 108px;
  } //IE11 style
  .flx-menu-header {
    & .flx-top-icon-menu {
      grid-column-end: span 1;
    }
  }
  .flx-app__session-rejoin,
  .flx-app__session-reminder {
    flex-wrap: wrap;
    padding-right: 8px;
    padding-bottom: 8px;
    &__text {
      margin-right: 8px;
    }
    &__actions {
      margin-top: 16px;
    }
  }
  .mdc-dialog {
    & .mdc-textfield__input {
      height: 100px;
    }
  }

  .flx-app__hero-card .mdc-card__title--large {
    line-height: 40px;
  }

  body:not(.dashboard-home) {
    .flx-app__legacy-content {
      max-width: 92%;
    }
  }
}

/* Tablet */
@media (min-width: map-get($mdc-layout-grid-breakpoints, 'tablet')) and (max-width: map-get($mdc-layout-grid-breakpoints, 'desktop') - 1px) {
  .hidden-tablet {
    display: none !important;
  }
  .visible-tablet {
    display: block !important;
  }
  .flx-app__hero-card {
    .flx-field {
      flex-basis: 100% !important;
    }

    .flx-action--separator {
      padding-top: 16px;
    }

    button,
    a {
      flex-grow: 1;
      margin-top: 16px;
    }

    .button_outbound-session-disabled {
      flex-grow: 0;
    }
  }
  .mdc-dialog__surface {
    margin: 0 40px;
  }
  .mdc-list-group {
    &.flx-list-group {
      & .mdc-list-item {
        &::before {
          display: inline-block;
        }
        & .show-hover {
          display: inline-block;
        }
      }
    }
  }
  _:-ms-fullscreen {
    min-height: 108px;
  } //IE11 style
  .flx-menu-header {
    & .flx-top-icon-menu {
      grid-column-end: span 6;
    }
  }
  .mdc-dialog {
    & .mdc-textfield__input {
      height: 100px;
    }
  }

  .new-contract-modal .modal-dialog {
    margin: 8px;
  }
}

/* Phone & Tablet */
@media (max-width: map-get($mdc-layout-grid-breakpoints, 'desktop') - 1px) {
  .flx-app__hero-card {
    .mdc-card__actions {
      flex-wrap: wrap;
    }
  }

  .mdc-list-group {
    &.flx-list-group {
      & .mdc-list-item {
        &:hover {
          background-color: transparent;
        }
        .flx-meeting--session-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }
  _:-ms-fullscreen {
    min-height: 108px;
  } //IE11 style
  .flx-menu-header {
    & .mdc-tab-bar-scroller {
      order: 2;
      margin: auto;
    }
    & .mdc-layout-grid__inner {
      grid-gap: 0;
    }
    .mdc-tab-bar-scroller__indicator--back {
      display: none;
      left: -17px;
      top: 16px;
    }
    .mdc-tab-bar-scroller__indicator--forward {
      display: none;
      right: -17px;
      top: 16px;
    }
    .mdc-tab-bar-scroller__indicator--enabled {
      display: inherit;
    }

    & .flx-app__logo {
      align-self: center;
      margin-left: 0;
    }
    & .flx-top-icon-menu {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .new-contract-modal .modal-dialog {
    width: auto !important;
    .row-save-user-actions,
    .row-confirm-actions {
      padding-top: 0;
      .btn {
        margin-top: 16px;
      }
    }

    .user-selection .ms-sel-ctn .ms-sel-item {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
    }

    .complete-process-message {
      padding: 0 16px;
    }
  }
}

/* Desktop */
@media (min-width: map-get($mdc-layout-grid-breakpoints, 'desktop')) {
  .hidden-desktop {
    display: none !important;
  }
  .visible-desktop {
    display: block !important;
  }

  .basic-demo-toolbar.show-scroll-menu {
    overflow: hidden;
  }

  .mdc-list-item__text__secondary {
    .visible-desktop {
      display: inline !important;
    }
  }

  .mdc-list-group.flx-list-group {
    .mdc-list-item__end-detail {
      width: 175px;
    }
  }

  .flx-app__hero-card {
    .mdc-card_outbound-session-disabled {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .mdc-tab-bar-scroller {
    margin-left: 16px;
  }

  .flx-top-icon-menu {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  .flx-app__logo {
    height: 56px;
  }
}

//TODO check if this can be added to the phone breaking point
@media (max-width: 767px) {
  .rct_panel-container {
    width: 100%;

    .rct_panel-content {
      .start-time {
        max-width: 100%;
      }
    }
  }

  .rct_panel-container--hidden {
    width: 0;
    overflow: hidden;
  }
}
