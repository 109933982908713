.autocomplete-suggestions {
  text-align: left;
  cursor: pointer;
  overflow: scroll;
  overflow-x: hidden;

  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 302; // needs to be above the modal, and the modal above header that has z-index 301
  box-sizing: border-box;

  .autocomplete-suggestion {
    transition: background .15s linear;
  }
  .autocomplete-suggestion.selected {
    background: #f5f5f5;
  }
}
