/**
 * Only IE 11 and above.
 */
_:-ms-fullscreen, :root {
    .mdc-list-item__text {
        display: block;
    }

    .mdc-list-item__text__secondary  {
        display: block;
    }

    .flx-call-overlay {
        background-color: rgba(89,98,106,0.9);
    }

    .flx-call-overlay__action .material-icons {
        line-height: 73px;
    }

    .basic-demo-toolbar.show-scroll-menu {
        overflow-x: hidden;
    }

    .left-side .mdc-layout-grid__cell--span-4-tablet,
    .left-side .mdc-layout-grid__cell--span-4-phone {
        margin: 8px !important;
    }

    .left-side .mdc-layout-grid__cell--span-12-desktop {
        margin: 12px;
    }

    // In IE11 material icons are not properly centered within their boundary
    // box, so we need to manually and approximately center them.
    .mdc-list-group .mdc-list-item:hover .show-hover {
        &.flx-button--session-view,
        &.flx-button-meeting-request-reschedule,
        &.flx-button-meeting-request-accept,
        &.flx-button-meeting-request-decline {
            display: inline !important;
        }
    }

    .material-icons {
        &.flx-button-clear {
            height: 18px;
        }

        &.flx-button--session-view,
        &.flx-context-menu-button,
        &.flx-meeting--icon-waiting-confirmation {
            display: inline;
        }

        &.flx-button--session-view,
        &.flx-button-meeting-request-reschedule,
        &.flx-button-meeting-request-accept,
        &.flx-button-meeting-request-decline {
            &.mdc-ripple-surface:after,
            &.mdc-ripple-surface:before {
               width: 175%;
            }
        }
    }
}

@media (min-width: map-get($mdc-layout-grid-breakpoints, 'desktop')) {
    _:-ms-fullscreen, :root {
        .mdc-tab-bar-scroller {
            width: calc(66.666% - 24px);
        }

        .mdc-tab-bar-scroller__indicator__inner {
            padding-top : 5px;
        }
    }
}

/**
 * Only Firefox
 */
@-moz-document url-prefix() {
    .flx-section-content .right-side {
        width: 100%
    }
}
