body:not(.dashboard-home) {
  color: $flx-theme-text;
  h1, .box-title {
    font-family: inherit;
    color: $flx-theme-text;
  }
  .fc-icon.fc-icon-fa {
    font-family: FontAwesome !important;
    font-size: 1em;
  }
  .content-subheader li a {
    color: $flx-theme-text;
  }
  .transparent {
    opacity: 0.6;
  }
  .flx-app__legacy-content {
    background-color: #ffffff;
    margin: 24px auto;
    max-width: 1392px;
    position: relative;
  }
  .service-create {
    padding-top: 10px;
    & select.form-control {
      text-align: right;
    }
  }
  .popover {
    max-width: 310px;
    z-index: 200;
  }
  .popover-close {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 900;
    font-size: 28px;
    padding: 0 10px 10px 10px;
    color: #000;
    margin-top: -6px;
  }
  .popover-title {
    padding-right: 40px;
  }
  .popover-content {
    font-size: 12px;
    overflow: hidden;
  }
  .content-subheader {
    border: none;
    margin-bottom: 0;
  }
  .fpt-box-dashboard {
    border: none;
    box-shadow: none;
    & .table-responsive {
      & thead {
        & tr:first-child > th {
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #f4f4f4;
          border-top: none;
          & a {
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            color: $mdc-theme-primary;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      & tbody {
        & a {
          color: $flx-theme-text;
          :hover {
            color: #72afd2;
          }
        }
        & button {
          &.btn.dropdown-toggle {
            padding-bottom: 7px;
            border: none;
            background-color: transparent;
            color: #777;
            &:hover {
              color: #000000;
            }
          }
        }
      }
      & .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px !important; // defined as important to overwrite the default one from Yii grids
      }
    }
    & .pagination,
    & .box-footer {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }


  & li.pull-right {
    background-color: inherit;
    border: none;
    & a.btn,
    & a.btn:hover {
      background: $mdc-theme-primary;
      color: #ffffff;
      border-radius: 4px;
    }
  }
  form,
  .marketing-links,
  .fpt-box-dashboard {
    padding: 10px 30px 24px 30px;
    & .box-header,
    & .box-footer,
    & .media-body,
    & .ui-sortable,
    & .box-body,
    & .content-header,
    & .dashboard-box-content2 .form-group {
      & .media-body,
      & a.media-left,
      & .marketing-message {
        padding: 0;
      }
    }
    & .marketing-links-service:not(:last-child){
      margin-bottom: 15px;
    }
    & .new-message-form {
      padding: 0;
      margin-top: 10px;
      & .send-message {
        margin-bottom: 0;
      }
    }
    & .session-postpone {
      & dt {
        float: left;
      }
      & dd {
        padding-left: 20px;
      }
      & .box-footer {
        padding-left: 15px;
      }
      & .box-footer {
        & .btn[disabled] {
          cursor: not-allowed;
          filter: alpha(opacity=65);
          -webkit-box-shadow: none;
          box-shadow: none;
          opacity: .65;
        }
        .btn {
          display: inline-block;
          padding: 10px 16px;
          margin-bottom: 0;
          font-size: 18px;
          line-height: 1.42857143;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          cursor: pointer;
          font-weight: normal;
          border-radius: 8px;
          touch-action: manipulation;
          user-select: none;
          border: 1px solid transparent;

          &.btn-success {
            box-shadow: none;
            background-color: #5cb85c;
            border-color: #5cb85c;
            color: #ffffff;
          }
          &.btn-success:hover {
            color: #fff;
            background-color: #449d44;
            border-color: #398439;
          }
        }
      }
    }

    .fc-non-business {
      background-color: rgba(246, 247, 251, 0.4);
    }
    & .full-calendar-caption {
      padding: 0 15px 0 15px;
      margin: 6px 0 15px 0;
      overflow: hidden;
      & .caption {
        float: left;
        margin-right: 12px;
        line-height: 0.95;
        margin-bottom: 5px;
        display: flex;
        & .name {
          margin: 0 5px 0 0;
        }
        & .look {
          height: 18px;
          width: 50px;
          float: left;
          margin-right: 5px;
          &.bussy {
            background-color: #dae2ee !important;
            opacity: 1;
            border: 1px solid #666666;
          }
          &.available {
            background-color: white;
            border: 1px solid #CCC;
          }
          &.today {
            background-color: #fcf8e3;
            border: 1px solid #CCC;
          }
        }
      }
    }
    & .session-invite-footer {
      position: fixed;
      bottom: 0;
      width: calc(100% - 230px);
      height: 60px;
      background-color: #F9F9F9;
      border-top: 1px solid #E9E9E9;
      margin: 0;
      z-index: 1000;
    }
  }
  .contracts-hint {
    padding: 24px 30px;
    margin: 0;
    & .fpt-col {
      display: table;
      & .col-label,
      & .col-description {
        padding-right: 5px;
        display: table-cell;
      }
    }
  }
  .dashboard-box-shadow {
    & .media-body,
    & .col-in-col {
      padding-right: 0;
    }
  }
  .dashboard-box {
    & .marketing-link {
      padding-left: 26px;
    }
    & .media-left {
      width: 58px;
      text-align: center;
      vertical-align: top;
      padding-right: 10px !important; // For overwritten a padding coming from bootstrap
    }
    & .media-right {
      white-space: nowrap;
      min-width: 120px;
      text-align: right;
    }
  }
  .flx-section-content {
    & .flx-header-grid {
      margin-top : 24px;
    }
    & .mail-conversation-last {
      padding: 15px;
    }
    & .mail-reply {
      & form {
        display: none;
      }
    }
    & .fpt-file-wrap {
      background-color: #FFFFFF;
      display: table;
      width: 100%;
      & .fpt-file {
        width: auto;
        border: 1px solid #EEEEEE;
        height: 34px;
        & .file-upload.btn {
          background-color: #3c8dbc;
          border-color: #367fa9;
          position: relative;
          overflow: hidden;
          float: left;
          height: 33px;
          border-radius: 0;
          & span {
            color: #ffffff;
          }
          & .upload {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            font-size: 20px;
            cursor: pointer;
            opacity: 0;
          }
        }
        & .file-text {
          padding: 6px 12px 6px 12px;
        }
      }
    }
    & #new-contract {
      & a {
        background: $mdc-theme-secondary;
        color: #ffffff; // Old style
        border-radius: 4px;
      }
    }

    & .fpt-file-wrap {
      background-color: #FFFFFF;
      display: table;
      width: 100%;
      & .fpt-file {
        width: auto;
        border: 1px solid #EEEEEE;
        height: 34px;
        & .file-upload.btn {
          background-color: #3c8dbc;
          border-color: #367fa9;
          position: relative;
          overflow: hidden;
          float: left;
          height: 33px;
          border-radius: 0;
          & span {
            color: #ffffff;
          }
          & .upload {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            font-size: 20px;
            cursor: pointer;
            opacity: 0;
          }
        }
      }
    }
    & #new-contract {
      & a {
        background: $mdc-theme-primary;
        color: #ffffff; // Old style
        border-radius: 4px;
      }
    }
  }

  .nav-tabs > li.active-left > a,
  .nav-tabs > li.active-left > a:hover,
  .nav-tabs > li.active-left > a:focus {
    border-left-width: 0 !important;
  }

  .message-success-more-options {
    color: $flx-theme-text;
  }
  .box-header {
    padding: 0 16px;
  }
  .content-header {
    margin: 0 10px;

    small {
      padding: 0 8px;
    }
  }
  .account-upload img, #account-avatar {
    width:240px;
    height:240px;
  }
}

body {

  // Patch for initialize modal contracts container
  .modal-contract-container {
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
  }
  &.dashboard-mail,
  &.dashboard-calendar,
  &.dashboard-client,
  &.dashboard-contract {
    & .content-header {
      display: none;
    }
  }
  &.dashboard-services,
  &.dashboard-account,
  &.dashboard-marketing,
  &.dashboard-chat,
  &.dashboard-sessions,
  &.dashboard-subscription {
    & .content-header {
      & ul.breadcrumb {
        display: none;
      }
    }
  }
  &.dashboard-marketing {
    & .tab-content {
      & div.tab-content {
        padding-top:10px;
      }
    }
  }
}

body .media-right a i {
  color: #ffffff;
}

ul.pagination li {
  &.active a {
    background: $mdc-theme-primary;
    color: #ffffff;
    border-color: transparent;
    &:hover {
      background: $mdc-theme-primary;
      border-color: transparent;
    }
  }
  a {
    color: $mdc-theme-primary;
  }
}

/* Styling Widget ActiveEngage */
.preview iframe {
  all: initial;
  border: 1px #ddd solid;
  height: 350px;
  border-radius: 3px;
}

.active-engage-preview {
  margin-left: 30px;
  margin-bottom: 15px;
}

/* Styling the link in account settings */
.dashboard-account {
  .media-account {
    #account-cookies {
      padding-left: 30px;
    }

    .account-link {
      color: #3A9EFB;
      cursor: pointer;
    }
  }
}
