// @import "./variables";

@mixin flx-border($p-value) {
  @if type-of($p-value) != number or not unitless($p-value) {
    @error "$p-value must be a unitless number";
  }

  @if $p-value < 0 or $p-value > 24 {
    @error "$p-value must be between 0 and 24";
  }

  border-width: $p-value;
  border-style: solid;
}

@for $p-value from 0 through 24 {
   .flx-border--p#{$p-value} {
    @include flx-border($p-value);
  }
}
