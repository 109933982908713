/* Custom Icons */
.fa-text:before {
  content: "\f086";
}

.fa-video:before {
  content: "\f03d";
}

.fa-expert_calls:before {
  content: "\f095";
}

.fa-experts_office:before {
  content: "\f015";
}

.fa-async:before {
  content: "\f021";
}

.fa.fa-service-text:before {
  content: "\f086";
}

.fa.fa-service-video:before {
  content: "\f03d";
}

.fa.fa-service-expert_calls:before {
  content: "\f095";
}

.fa.fa-service-experts_office:before {
  content: "\f015";
}