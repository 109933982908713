// Color definitions for Tooltips
$palette-grey:
        "250,250,250"
        "245,245,245"
        "238,238,238"
        "224,224,224"
        "189,189,189"
        "158,158,158"
        "117,117,117"
        "97,97,97"
        "66,66,66"
        "33,33,33";
$color-white: "255,255,255";
$palette-grey-50: nth($palette-grey, 1);
$palette-grey-100: nth($palette-grey, 2);
$palette-grey-200: nth($palette-grey, 3);
$palette-grey-300: nth($palette-grey, 4);
$palette-grey-400: nth($palette-grey, 5);
$palette-grey-500: nth($palette-grey, 6);
$palette-grey-600: nth($palette-grey, 7);
$palette-grey-700: nth($palette-grey, 8);
$palette-grey-800: nth($palette-grey, 9);
$palette-grey-900: nth($palette-grey, 10);

// Tooltip variables
$tooltip-text-color: unquote("rgb(#{$color-white})") !default;
$tooltip-background-color: unquote("rgba(#{$palette-grey-700}, 0.9)") !default;
$tooltip-font-size: 10px !default;
$tooltip-font-size-large: 14px !default;
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;