@mixin flx-spacing($pixel, $property) {
  &--t#{$pixel} {
    #{$property}-top: #{$pixel}px;
  }
  &--b#{$pixel} {
    #{$property}-bottom: #{$pixel}px;
  }
  &--l#{$pixel} {
    #{$property}-left: #{$pixel}px;
  }
  &--r#{$pixel} {
    #{$property}-right: #{$pixel}px;
  }
}

.flx-margin {
  @for $pixel from 1 through 32 {
    @include flx-spacing($pixel, margin);
  }
}

.flx-padding {
  @for $pixel from 1 through 32 {
    @include flx-spacing($pixel, padding);
  }
}
