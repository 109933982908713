/**
 * Styles for services page in the bashboard
 */

.dashboard-services .mdc-card {
  padding: 10px 20px;
  flex-direction: row;
  flex-flow: row wrap;

  .content-header {
    flex: 1;
    margin: 1.2rem 0 0 0;
    padding: 0;

    h1 { margin: 0 0 0 -.5rem; }
  }
  .flx-services__button {
    flex: 1 1 auto;
    margin-top: 1rem;
    margin-right: .1rem;
    @media (min-width: map-get($mdc-layout-grid-breakpoints, 'tablet')) {
      flex: 0 1 auto;
    }
  }
  .flx-services__intro {
    flex: 1 1 100%;
    margin-top: 1rem;
  }
  .flx-services__list {
    flex: 1 1 100%;
    margin-top: 1rem;
    width: 100%;
  }
}

.flx-services__service-add:not(:disabled) {
  background-color: $mdc-theme-primary;
  color: #FFF;
  outline: 0;

  &:hover, &:focus {
    background-color: $mdc-theme-primary-light;
  }
}

.flx-service {
  padding: 1rem;

  @media (min-width: map-get($mdc-layout-grid-breakpoints, 'tablet')) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
.flx-service--hidden {
  opacity: .6;
}

.flx-service__left {
  flex: 1;
  min-width: 0;
  max-width: 100%;
}
.flx-service__name {
  display: inline-block;
}
.flx-service__description {
  overflow: hidden;
  text-overflow: ellipsis;
}
.flx-service__updatelink {
  color: #333;
}
.flx-service__updatelink--disabled {
  &:hover, &:active {
    color: #333;
    cursor: default;
  }
}

.flx-service__right {
  margin-top: 5px;
  min-width: 140px;

  @media (min-width: map-get($mdc-layout-grid-breakpoints, 'tablet')) {
    text-align: right;
  }
}
.flx-service__right-btn {
  color: #FFF;
}
.flx-service__conditions {
  margin-bottom: 9px;
}

.flx-service__offered-languages {
  display: flex;
}
.flx-service__offered-languages-label {
  margin-right: 6px;
  color: #999999;
}
.flx-service__offered-languages-flag {
  margin-right: 2px;
  margin-left: 2px;
}

.services.ui-sortable {
  .ui-sortable-item:hover {
    cursor: ns-resize;
  }
}

.mdc-dialog-services {
  .mdc-dialog__surface {
    max-width: 500px;
    background-color: #FFF;
    border-radius: .4rem;
    position: relative;
  }
  .mdc-dialog__close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 40px;
    min-width: 0;
    transform: rotate(45deg);
    font-weight: normal;
    color: #333;
    padding: 1px 2px 1px 4px;
  }
  .mdc-dialog__header__title {
    color: #333;
  }
  .mdc-dialog-service-form {
    padding: 0;
  }
}

.mdc-dialog-services__nontranslatable-fields {
  display: flex;
}
.field-service-type, .field-service-unit{
  flex: 1;
}
.field-service-type {
  margin-right: 20px;
}
.service-description {
  resize: none;
}

.mdc-dialog-services__pane {
  display: none;
}
.mdc-dialog-services__pane--active {
  display: block;
}

.mdc-dialog__footer {
  padding: 1rem;
}
.mdc-dialog__footer .mdc-dialog-service__save-btn {
  background-color: $mdc-theme-primary;
  color: #FFF;

  &[disabled] {
    opacity: .5;
  }

  &:hover, &:focus {
    background-color: $mdc-theme-primary-light;
  }
}
